import { EyeOutlined, ToolOutlined } from "@ant-design/icons";
import {
	CalendarOutlined,
	CheckCircleOutlined,
	EnvironmentOutlined,
	FieldTimeOutlined,
	HistoryOutlined,
	HomeOutlined,
	IdcardOutlined,
	LoginOutlined,
	LogoutOutlined,
	MedicineBoxOutlined,
	SafetyCertificateOutlined,
	ShopOutlined,
	SolutionOutlined,
	TeamOutlined,
	UserOutlined,
} from "@ant-design/icons";
import React from "react";
import { ROLES } from "./permissions";

export const menuGroups = {
	rrhh: {
		key: "rrhh",
		title: "RRHH",
		icon: <IdcardOutlined />,
	},
	users: {
		key: "users",
		title: "Usuarios",
		icon: <TeamOutlined />,
	},
};

export const routes = {
	home: {
		key: "home",
		slug: "home",
		to: "/home",
		title: "Dashboard",
		icon: <HomeOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		role: [ROLES.admin.role, ROLES.user.role],
	},

	singUp: {
		key: "singUp",
		slug: "singUp",
		to: "/singUp",
		title: "Fichar",
		icon: <CheckCircleOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		group: "rrhh",
	},

	mySingUps: {
		key: "mySingUps",
		slug: "mySingUps",
		to: "/mySingUps",
		title: "Mis fichajes",
		icon: <HistoryOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		group: "rrhh",
	},

	singUps: {
		key: "singUps",
		slug: "singUps",
		to: "/singUps",
		title: "Fichajes",
		icon: <CheckCircleOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		group: "rrhh",
	},

	mapSingUps: {
		key: "mapSingUps",
		slug: "mapSingUps",
		to: "/mapSingUps",
		title: "Mapa de fichajes",
		icon: <EnvironmentOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		group: "rrhh",
	},

	absences: {
		key: "absences",
		slug: "absences",
		to: "/absences",
		title: "Bajas médicas",
		icon: <MedicineBoxOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		group: "rrhh",
	},

	permisions: {
		key: "permisions",
		slug: "permisions",
		to: "/permisions",
		title: "Permisos",
		icon: <SafetyCertificateOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		group: "rrhh",
	},

	holidays: {
		key: "holidays",
		slug: "holidays",
		to: "/holidays",
		title: "Vacaciones",
		icon: <CalendarOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		group: "rrhh",
	},

	petitions: {
		key: "petitions",
		slug: "petitions",
		to: "/petitions",
		title: "Peticiones",
		icon: <SolutionOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		group: "rrhh",
	},

	profile: {
		key: "profile",
		slug: "profile",
		to: "/profile",
		title: "Perfil",
		icon: <UserOutlined />,
		showInMenu: false,
		showHeader: true,
		showSearch: true,
		action: null,
		role: [ROLES.admin.role, ROLES.user.role],
	},

	users: {
		key: "users",
		slug: "users",
		to: "/users",
		title: "Usuarios",
		icon: <UserOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		role: [ROLES.admin.role],
		group: "users",
	},

	roles: {
		key: "roles",
		slug: "roles",
		to: "/roles",
		title: "Roles",
		icon: <TeamOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		group: "users",
	},

	login: {
		key: "login",
		slug: "login",
		to: "/login",
		title: "LOGIN",
		icon: <LoginOutlined />,
		showInMenu: false,
		showHeader: false,
		showSearch: false,
		action: null,
		role: [ROLES.admin.role, ROLES.user.role],
	},

	customers: {
		key: "customers",
		slug: "customers",
		to: "/customers",
		title: "Clientes",
		icon: <HomeOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		role: [ROLES.admin.role],
		group: "users",
	},
	laundrys: {
		key: "laundrys",
		slug: "laundrys",
		to: "/laundrys",
		title: "Lavanderías",
		icon: <ShopOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		role: [ROLES.admin.role],
	},
	resources: {
		key: "resources",
		slug: "resources",
		to: "/resources",
		title: "Recursos",
		icon: <FieldTimeOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		role: [ROLES.admin.role],
	},
	equipments: {
		key: "equipments",
		slug: "equipments",
		to: "/equipments",
		title: "Equipos",
		icon: <ToolOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		role: [ROLES.admin.role],
	},
	equipmentView: {
		key: "equipmentView",
		slug: "equipmentView",
		to: "/equipmentView",
		title: "Vista de Equipos",
		icon: <EyeOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: false,
		action: null,
		role: [ROLES.admin.role, ROLES.user.role],
	},
	logout: {
		key: "logout",
		slug: "logout",
		to: "/logout",
		title: "Log out",
		icon: <LogoutOutlined />,
		showInMenu: false,
		showHeader: false,
		showSearch: false,
		action: null,
		role: [ROLES.admin.role, ROLES.user.role],
	},
};
