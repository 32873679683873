import moment from "moment";
import "moment/locale/es";
import { createSelector } from "reselect";
moment().locale("es");

/* APP */
export const isUserAuthorized = createSelector(
	(state) => state.authorization,
	(authorization) => authorization
);
export const getCurrentRoute = createSelector(
	(state) => state.currentRoute,
	(currentRoute) => currentRoute
);
export const getCurrentRouteTitle = createSelector(getCurrentRoute, (route) => route.title);
export const getSideMenuState = createSelector(
	(state) => state.app,
	(app) => app.isCollapsedSideMenu
);
export const getCurrentTheme = createSelector(
	(state) => state.currentTheme,
	(currentTheme) => currentTheme
);

/* PROFILE */
export const getUserProfile = createSelector(
	(state) => state.profile,
	(profile) => profile
);
export const currentUserId = createSelector(
	(state) => state.profile,
	(profile) => profile && profile.id
);

export const getCurrentUserRole = createSelector(getUserProfile, (profile) => (!profile ? null : profile.role));

/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
	(state) => state.notifications,
	(notifications) => notifications
);

/* USERS */
export const getUsers = createSelector(
	(state) => state.users,
	(users) => users
);
export const getUsersForm = createSelector(
	(state) => state.usersForm,
	(form) => form
);

/* ALERT */
export const getAlerts = createSelector(
	(state) => state.alerts,
	(alerts) => alerts
);
export const getAlertsForm = createSelector(
	(state) => state.alertsForm,
	(form) => form
);

/* SINGUPS */
export const getSingUps = createSelector(
	(state) => state.singUps,
	(singUps) => singUps
);
export const getSingUpsForm = createSelector(
	(state) => state.singUpsForm,
	(form) => form
);

/* PETITIONS */
export const getPetitions = createSelector(
	(state) => state.petitions,
	(petitions) => petitions
);
export const getPetitionsForm = createSelector(
	(state) => state.petitionsForm,
	(form) => form
);

/* HOLIDAYS */
export const getHolidays = createSelector(
	(state) => state.holidays,
	(holidays) => holidays
);
export const getHolidaysForm = createSelector(
	(state) => state.holidaysForm,
	(form) => form
);

/* ABSENCES */
export const getAbsences = createSelector(
	(state) => state.absences,
	(absences) => absences
);
export const getAbsencesForm = createSelector(
	(state) => state.absencesForm,
	(form) => form
);

/* PERMISIONS */
export const getPermisions = createSelector(
	(state) => state.permisions,
	(permisions) => permisions
);
export const getPermisionsForm = createSelector(
	(state) => state.permisionsForm,
	(form) => form
);

/* ROLES */
export const getRoles = createSelector(
	(state) => state.roles,
	(roles) => roles
);
export const getRolesForm = createSelector(
	(state) => state.rolesForm,
	(form) => form
);

/* CUSTOMERS */
export const getCustomers = createSelector(
	(state) => state.customers,
	(customers) => customers
);
export const getCustomersForm = createSelector(
	(state) => state.customersForm,
	(form) => form
);

/* LAUNDRYS */
export const getLaundrys = createSelector(
	(state) => state.laundrys,
	(laundrys) => laundrys
);
export const getLaundrysForm = createSelector(
	(state) => state.laundrysForm,
	(form) => form
);

/* RESOURCES */
export const getResources = createSelector(
	(state) => state.resources,
	(resources) => resources
);
export const getResourcesForm = createSelector(
	(state) => state.resourcesForm,
	(form) => form
);

export const getSelectedLaundry = createSelector(
	(state) => state.selectedLaundry,
	(selectedLaundry) => selectedLaundry
);

/* EQUIPMENTS */
export const getEquipments = createSelector(
    (state) => state.equipments,
    (equipments) => equipments
);
export const getEquipmentsForm = createSelector(
    (state) => state.equipmentsForm,
    (form) => form
);
