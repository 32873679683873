import { handleActions, handleAction } from "redux-actions";
import {
	AUTH,
	SET_PROFILE,
	SET_CURRENT_ROUTE,
	LOGOUT,
	SHOW_NOTIFICATION,
	CLOSE_NOTIFICATION,
	SET_SIDE_MENU_STATE,
	OPEN_ALERT_FORM,
	CLOSE_ALERT_FORM,
	EDIT_SELECTED_ALERT,
	ALERT_FORM_TOOGLE_LOADING,
	SET_ALERT_LIST,
	ADD_NEW_ALERT,
	UPDATE_ALERT,
	DELETE_ALERT,
	THEME,
	ADD_NEW_HOLIDAY,
	SET_HOLIDAY_LIST,
	UPDATE_HOLIDAY,
	DELETE_HOLIDAY,
	OPEN_HOLIDAY_FORM,
	CLOSE_HOLIDAY_FORM,
	EDIT_SELECTED_HOLIDAY,
	HOLIDAY_FORM_TOOGLE_LOADING,
	ADD_NEW_PETITION,
	SET_PETITION_LIST,
	UPDATE_PETITION,
	DELETE_PETITION,
	OPEN_PETITION_FORM,
	CLOSE_PETITION_FORM,
	EDIT_SELECTED_PETITION,
	PETITION_FORM_TOOGLE_LOADING,
	ADD_NEW_SINGUP,
	SET_SINGUP_LIST,
	UPDATE_SINGUP,
	DELETE_SINGUP,
	OPEN_SINGUP_FORM,
	CLOSE_SINGUP_FORM,
	EDIT_SELECTED_SINGUP,
	SINGUP_FORM_TOOGLE_LOADING,
	ADD_NEW_PERMISION,
	SET_PERMISION_LIST,
	UPDATE_PERMISION,
	DELETE_PERMISION,
	OPEN_PERMISION_FORM,
	CLOSE_PERMISION_FORM,
	EDIT_SELECTED_PERMISION,
	PERMISION_FORM_TOOGLE_LOADING,
	ADD_NEW_ABSENCE,
	SET_ABSENCE_LIST,
	UPDATE_ABSENCE,
	DELETE_ABSENCE,
	OPEN_ABSENCE_FORM,
	CLOSE_ABSENCE_FORM,
	EDIT_SELECTED_ABSENCE,
	ABSENCE_FORM_TOOGLE_LOADING,
	ADD_NEW_ROLE,
	SET_ROLE_LIST,
	UPDATE_ROLE,
	DELETE_ROLE,
	OPEN_ROLE_FORM,
	CLOSE_ROLE_FORM,
	EDIT_SELECTED_ROLE,
	ROLE_FORM_TOOGLE_LOADING,
	ADD_NEW_LAUNDRY,
	SET_LAUNDRY_LIST,
	UPDATE_LAUNDRY,
	DELETE_LAUNDRY,
	OPEN_LAUNDRY_FORM,
	CLOSE_LAUNDRY_FORM,
	EDIT_SELECTED_LAUNDRY,
	LAUNDRY_FORM_TOOGLE_LOADING,
	ADD_NEW_RESOURCE,
	SET_RESOURCE_LIST,
	UPDATE_RESOURCE,
	DELETE_RESOURCE,
	OPEN_RESOURCE_FORM,
	CLOSE_RESOURCE_FORM,
	EDIT_SELECTED_RESOURCE,
	RESOURCE_FORM_TOOGLE_LOADING,
	SET_SELECTED_LAUNDRY,
  ADD_NEW_EQUIPMENT,
  SET_EQUIPMENT_LIST,
  UPDATE_EQUIPMENT,
  DELETE_EQUIPMENT,
  OPEN_EQUIPMENT_FORM,
  CLOSE_EQUIPMENT_FORM,
  EDIT_SELECTED_EQUIPMENT,
  EQUIPMENT_FORM_TOOGLE_LOADING,
} from "./../../constants";
import { createCrudReducers } from "../../utils/crudGenerator";

/* BASIC */
export const authorization = handleActions(
	{
		[AUTH]: (state, action) => action.payload,
		[LOGOUT]: () => ({ auth: false }),
	},
	null
);

/* PROFILE */
export const profile = handleActions(
	{
		[SET_PROFILE]: (state, action) => action.payload,
	},
	null
);

/* APP */
export const currentRoute = handleAction(SET_CURRENT_ROUTE, (state, action) => action.payload, {});

export const notifications = handleActions(
	{
		[SHOW_NOTIFICATION]: (state, action) => action.payload,
		[CLOSE_NOTIFICATION]: (state, action) => ({
			show: false,
			status: null,
			message: null,
		}),
	},
	{ show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
	{
		[SET_SIDE_MENU_STATE]: (state, action) => {
			return { ...state, isCollapsedSideMenu: action.payload };
		},
	},
	{ isCollapsedSideMenu: true }
);

export const currentTheme = handleAction(THEME, (state, action) => action.payload, false);

export const alerts = handleActions(
	{
		[SET_ALERT_LIST]: (state, action) => action.payload,
		[ADD_NEW_ALERT]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[UPDATE_ALERT]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[DELETE_ALERT]: (state, action) => {
			action.payload.forEach((number) => {
				delete state[number];
			});
			return { ...state };
		},
	},
	null
);

export const alertsForm = handleActions(
	{
		[OPEN_ALERT_FORM]: (state) => ({
			show: !state.show,
			data: null,
			loading: false,
		}),
		[CLOSE_ALERT_FORM]: () => ({ show: false, data: null, loading: false }),
		[EDIT_SELECTED_ALERT]: (s, action) => ({
			show: true,
			data: action.payload,
			loading: false,
		}),
		[ALERT_FORM_TOOGLE_LOADING]: (state) => ({
			...state,
			loading: !state.loading,
		}),
	},
	{ show: false, data: null, loading: false }
);

const userReducers = createCrudReducers("USER");

export const users = userReducers.list;
export const usersForm = userReducers.form;

// HOLIDAYS
export const holidays = handleActions(
	{
		[SET_HOLIDAY_LIST]: (state, action) => action.payload,
		[ADD_NEW_HOLIDAY]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[UPDATE_HOLIDAY]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[DELETE_HOLIDAY]: (state, action) => {
			action.payload.forEach((number) => {
				delete state[number];
			});
			return { ...state };
		},
	},
	null
);

export const holidaysForm = handleActions(
	{
		[OPEN_HOLIDAY_FORM]: (state) => ({
			show: !state.show,
			data: null,
			loading: false,
		}),
		[CLOSE_HOLIDAY_FORM]: () => ({
			show: false,
			data: null,
			loading: false,
		}),
		[EDIT_SELECTED_HOLIDAY]: (s, action) => ({
			show: true,
			data: action.payload,
			loading: false,
		}),
		[HOLIDAY_FORM_TOOGLE_LOADING]: (state) => ({
			...state,
			loading: !state.loading,
		}),
	},
	{ show: false, data: null, loading: false }
);

// SINGUPS
export const singUps = handleActions(
	{
		[SET_SINGUP_LIST]: (state, action) => action.payload,
		[ADD_NEW_SINGUP]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[UPDATE_SINGUP]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[DELETE_SINGUP]: (state, action) => {
			action.payload.forEach((number) => {
				delete state[number];
			});
			return { ...state };
		},
	},
	null
);

export const singUpsForm = handleActions(
	{
		[OPEN_SINGUP_FORM]: (state) => ({
			show: !state.show,
			data: null,
			loading: false,
		}),
		[CLOSE_SINGUP_FORM]: () => ({
			show: false,
			data: null,
			loading: false,
		}),
		[EDIT_SELECTED_SINGUP]: (s, action) => ({
			show: true,
			data: action.payload,
			loading: false,
		}),
		[SINGUP_FORM_TOOGLE_LOADING]: (state) => ({
			...state,
			loading: !state.loading,
		}),
	},
	{ show: false, data: null, loading: false }
);

// PETITIONS
export const petitions = handleActions(
	{
		[SET_PETITION_LIST]: (state, action) => action.payload,
		[ADD_NEW_PETITION]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[UPDATE_PETITION]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[DELETE_PETITION]: (state, action) => {
			action.payload.forEach((number) => {
				delete state[number];
			});
			return { ...state };
		},
	},
	null
);

export const petitionsForm = handleActions(
	{
		[OPEN_PETITION_FORM]: (state) => ({
			show: !state.show,
			data: null,
			loading: false,
		}),
		[CLOSE_PETITION_FORM]: () => ({
			show: false,
			data: null,
			loading: false,
		}),
		[EDIT_SELECTED_PETITION]: (s, action) => ({
			show: true,
			data: action.payload,
			loading: false,
		}),
		[PETITION_FORM_TOOGLE_LOADING]: (state) => ({
			...state,
			loading: !state.loading,
		}),
	},
	{ show: false, data: null, loading: false }
);

// ABSENCES
export const absences = handleActions(
	{
		[SET_ABSENCE_LIST]: (state, action) => action.payload,
		[ADD_NEW_ABSENCE]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[UPDATE_ABSENCE]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[DELETE_ABSENCE]: (state, action) => {
			action.payload.forEach((number) => {
				delete state[number];
			});
			return { ...state };
		},
	},
	null
);

export const absencesForm = handleActions(
	{
		[OPEN_ABSENCE_FORM]: (state) => ({
			show: !state.show,
			data: null,
			loading: false,
		}),
		[CLOSE_ABSENCE_FORM]: () => ({
			show: false,
			data: null,
			loading: false,
		}),
		[EDIT_SELECTED_ABSENCE]: (s, action) => ({
			show: true,
			data: action.payload,
			loading: false,
		}),
		[ABSENCE_FORM_TOOGLE_LOADING]: (state) => ({
			...state,
			loading: !state.loading,
		}),
	},
	{ show: false, data: null, loading: false }
);

// PERMISIONS
export const permisions = handleActions(
	{
		[SET_PERMISION_LIST]: (state, action) => action.payload,
		[ADD_NEW_PERMISION]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[UPDATE_PERMISION]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[DELETE_PERMISION]: (state, action) => {
			action.payload.forEach((number) => {
				delete state[number];
			});
			return { ...state };
		},
	},
	null
);

export const permisionsForm = handleActions(
	{
		[OPEN_PERMISION_FORM]: (state) => ({
			show: !state.show,
			data: null,
			loading: false,
		}),
		[CLOSE_PERMISION_FORM]: () => ({
			show: false,
			data: null,
			loading: false,
		}),
		[EDIT_SELECTED_PERMISION]: (s, action) => ({
			show: true,
			data: action.payload,
			loading: false,
		}),
		[PERMISION_FORM_TOOGLE_LOADING]: (state) => ({
			...state,
			loading: !state.loading,
		}),
	},
	{ show: false, data: null, loading: false }
);

// ROLES
export const roles = handleActions(
	{
		[SET_ROLE_LIST]: (state, action) => action.payload,
		[ADD_NEW_ROLE]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[UPDATE_ROLE]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[DELETE_ROLE]: (state, action) => {
			action.payload.forEach((number) => {
				delete state[number];
			});
			return { ...state };
		},
	},
	null
);

export const rolesForm = handleActions(
	{
		[OPEN_ROLE_FORM]: (state) => ({
			show: !state.show,
			data: null,
			loading: false,
		}),
		[CLOSE_ROLE_FORM]: () => ({
			show: false,
			data: null,
			loading: false,
		}),
		[EDIT_SELECTED_ROLE]: (s, action) => ({
			show: true,
			data: action.payload,
			loading: false,
		}),
		[ROLE_FORM_TOOGLE_LOADING]: (state) => ({
			...state,
			loading: !state.loading,
		}),
	},
	{ show: false, data: null, loading: false }
);
const customerReducers = createCrudReducers("CUSTOMER");

export const customers = customerReducers.list;
export const customersForm = customerReducers.form;

// LAUNDRYS
export const laundrys = handleActions(
	{
		[SET_LAUNDRY_LIST]: (state, action) => action.payload,
		[ADD_NEW_LAUNDRY]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[UPDATE_LAUNDRY]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[DELETE_LAUNDRY]: (state, action) => {
			action.payload.forEach((number) => {
				delete state[number];
			});
			return { ...state };
		},
	},
	null
);

export const laundrysForm = handleActions(
	{
		[OPEN_LAUNDRY_FORM]: (state) => ({
			show: !state.show,
			data: null,
			loading: false,
		}),
		[CLOSE_LAUNDRY_FORM]: () => ({
			show: false,
			data: null,
			loading: false,
		}),
		[EDIT_SELECTED_LAUNDRY]: (s, action) => ({
			show: true,
			data: action.payload,
			loading: false,
		}),
		[LAUNDRY_FORM_TOOGLE_LOADING]: (state) => ({
			...state,
			loading: !state.loading,
		}),
	},
	{ show: false, data: null, loading: false }
);

// RESOURCES
export const resources = handleActions(
	{
		[SET_RESOURCE_LIST]: (state, action) => action.payload,
		[ADD_NEW_RESOURCE]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[UPDATE_RESOURCE]: (state, action) => {
			const newState = {
				...state,
				[action.payload.value]: action.payload,
			};
			return newState;
		},
		[DELETE_RESOURCE]: (state, action) => {
			action.payload.forEach((number) => {
				delete state[number];
			});
			return { ...state };
		},
	},
	null
);

export const resourcesForm = handleActions(
	{
		[OPEN_RESOURCE_FORM]: (state) => ({
			show: !state.show,
			data: null,
			loading: false,
		}),
		[CLOSE_RESOURCE_FORM]: () => ({
			show: false,
			data: null,
			loading: false,
		}),
		[EDIT_SELECTED_RESOURCE]: (s, action) => ({
			show: true,
			data: action.payload,
			loading: false,
		}),
		[RESOURCE_FORM_TOOGLE_LOADING]: (state) => ({
			...state,
			loading: !state.loading,
		}),
	},
	{ show: false, data: null, loading: false }
);

export const selectedLaundry = handleAction(SET_SELECTED_LAUNDRY, (state, action) => action.payload, null);

// EQUIPMENTS
export const equipments = handleActions(
    {
        [SET_EQUIPMENT_LIST]: (state, action) => action.payload,
        [ADD_NEW_EQUIPMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_EQUIPMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_EQUIPMENT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const equipmentsForm = handleActions(
    {
        [OPEN_EQUIPMENT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_EQUIPMENT_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_EQUIPMENT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [EQUIPMENT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);
