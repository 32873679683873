import { combineReducers } from "redux";
import {
	authorization,
	profile,
	currentRoute,
	notifications,
	app,
	usersForm,
	users,
	alerts,
	alertsForm,
	currentTheme,
	singUps,
	singUpsForm,
	petitions,
	petitionsForm,
	holidays,
	holidaysForm,
	roles,
	rolesForm,
	absences,
	absencesForm,
	permisions,
	permisionsForm,
	customers,
	customersForm,
	laundrys,
	laundrysForm,
	resources,
	resourcesForm,
	selectedLaundry,
  equipments,
  equipmentsForm,
} from "./Reducers";

export default combineReducers({
  equipments,
  equipmentsForm,
	resources,
	resourcesForm,
	laundrys,
	laundrysForm,
	customers,
	customersForm,
	app,
	authorization,
	currentRoute,
	profile,
	notifications,
	usersForm,
	users,
	alerts,
	alertsForm,
	currentTheme,
	singUps,
	singUpsForm,
	petitions,
	petitionsForm,
	holidays,
	holidaysForm,
	roles,
	rolesForm,
	absences,
	absencesForm,
	permisions,
	permisionsForm,
	selectedLaundry,
});
