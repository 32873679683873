export const API = {
	equipments: {
    create: `/equipment`,
    edit: `/equipment/`,
    delete: `/equipment/`,
    list: `/equipment`,
  },
	resources: {
    create: `/resource`,
    edit: `/resource/`,
    delete: `/resource/`,
    list: `/resource`,
  },
	laundrys: {
    create: `/laundry`,
    edit: `/laundry/`,
    delete: `/laundry/`,
    list: `/laundry`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
  roles: {
    create: `/role`,
    edit: `/role/`,
    delete: `/role/`,
    list: `/role`,
  },
  holidays: {
    create: `/holiday`,
    edit: `/holiday/`,
    delete: `/holiday/`,
    list: `/holiday`,
  },
  petitions: {
    create: `/petition`,
    edit: `/petition/`,
    delete: `/petition/`,
    list: `/petition`,
  },
  singUps: {
    create: `/singUp`,
    edit: `/singUp/`,
    delete: `/singUp/`,
    list: `/singUp`,
  },
  permisions: {
    create: `/permision`,
    edit: `/permision/`,
    delete: `/permision/`,
    list: `/permision`,
  },
  absences: {
    create: `/absence`,
    edit: `/absence/`,
    delete: `/absence/`,
    list: `/absence`,
  },
};
