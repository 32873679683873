import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_LAUNDRY,
    DELETE_LAUNDRY,
    LAUNDRY_FORM_TOOGLE_LOADING,
    SET_LAUNDRY_LIST,
    SHOW_NOTIFICATION,
    UPDATE_LAUNDRY,
} from "../constants";
import { formatLaundrys } from "./settingsActionsUtils";

/* LAUNDRY LIST */
export const fetchLaundrys = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.laundrys.list)
            .then((response) => {
                const laundrys = formatLaundrys(response.data);
                dispatch({
                    type: SET_LAUNDRY_LIST,
                    payload: keyBy(laundrys, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// LAUNDRYS
export const createLaundry = (newLaundry) => {
    return async (dispatch) => {
        dispatch({ type: LAUNDRY_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.laundrys.create, newLaundry)
            .then((response) => {
                const laundry = formatLaundrys(response.data);
                dispatch({ type: ADD_NEW_LAUNDRY, payload: laundry });
                dispatch({ type: LAUNDRY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Lavandería creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LAUNDRY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateLaundry = (updatedLaundry) => {
    return async (dispatch) => {
        dispatch({ type: LAUNDRY_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.laundrys.edit}${updatedLaundry && updatedLaundry._id}`, updatedLaundry)
            .then((response) => {
                const laundry = formatLaundrys(response.data);
                dispatch({ type: UPDATE_LAUNDRY, payload: laundry });
                dispatch({ type: LAUNDRY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Lavandería actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LAUNDRY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteLaundrys = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: LAUNDRY_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.laundrys.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_LAUNDRY, payload: Ids });
                dispatch({ type: LAUNDRY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Lavandería eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LAUNDRY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
